<template lang="html">
  <div class="Table">
    <simplebar class="TableScrollable">
      <div class="TableScrollable__wrapper">
        <TableHeader
          @sortChanged="orderBy"
          :ordered-by="orderedBy"
          :columns="table.header"
        />

        <main class="TableBody">
          <div
            class="TableItem"
            v-for="item in chunkedList[listQuery.page - 1]"
            :key="item.id"
          >
            <div class="TableColumn TableColumn--large TableColumn--profile">
              <profile-pic :size="40" :email="item.email" />
              <div class="ml-8">{{ item.name }}</div>
            </div>
            <div class="TableColumn TableColumn--short">
              <tag-item>
                {{ item.pivot.role | capitalize }}
              </tag-item>
            </div>
            <div class="TableColumn TableColumn--flex url">
              {{ item.email }}
            </div>
            <div class="TableColumn TableColumn--medium">
              <div class="actions">
                <action
                  icon="rubbish-bin"
                  table
                  @actionClicked="removeMember(item)"
                ></action>
              </div>
            </div>
          </div>
        </main>
      </div>
    </simplebar>

    <footer>
      <Pagination
        :total="this.total"
        :page="this.listQuery.page"
        :limit="this.listQuery.limit"
        @pagination="handlePagination"
        :pagination-history="paginationHistory"
      ></Pagination>
    </footer>
  </div>
</template>

<script>
  import TableMixin from '@/mixins/TableMixin'

  export default {
    name: 'TeamMembersTable',
    mixins: [TableMixin],
    data: function() {
      return {
        total: this.$store.getters['invitations/totalPending'],
        table: {
          header: [
            {
              label: 'Name',
              sorted: true,
              sortProp: 'name',
              size: 'large',
            },
            {
              label: 'Role',
              sorted: true,
              sortProp: 'pivot.role',
              size: 'short',
            },
            {
              label: 'Email',
              sorted: true,
              sortProp: 'email',
              size: 'flex',
            },
            {
              label: 'Actions',
              size: 'medium',
            },
          ],
        },
      }
    },
    methods: {
      deleteInvitation(invitation) {
        this.$store.dispatch('invitations/DELETE_INVITATION', {
          team: invitation.team_id,
          id: invitation.id,
        })
      },

      removeMember(member) {
        this.$emit('removeMember', member)
      },
    },
  }
</script>
