<template lang="html">
  <div class="Dashboard">
    <dashboard-top>
      <screen-title title="Selectors"></screen-title>
    </dashboard-top>
    <div class="container-full">
      <div class="dashboard__section">
        <section-title title="Invite new selectors" />
        <ValidationObserver v-slot="{ handleSubmit }" slim ref="invitationForm">
          <form
            @submit.prevent="handleSubmit(handleSelectorInvite)"
            class="Form Form--inline"
          >
            <formly-form
              :form="form"
              :model="invite"
              :fields="fields"
              tag="div"
              class="Form__group"
            ></formly-form>
            <button class="btn btn--primary">Send Invitation</button>
          </form>
        </ValidationObserver>
      </div>
      <div class="dashboard__section mt-48">
        <section-title title="Pending invitations" />

        <fade-transition mode="out-in">
          <TableLoader v-if="!loaded" />
          <InvitationsTable
            :items="pending"
            :key="pending.length"
            v-if="loaded && pending.length > 0"
          />
          <empty-message v-if="loaded && pending.length == 0">
            No invitations were send.
          </empty-message>
        </fade-transition>
      </div>
      <div class="dashboard__section mt-48">
        <section-title title="Team Members" />

        <fade-transition mode="out-in">
          <TableLoader v-if="!loaded" />
          <TeamTable
            :items="filteredMembers"
            :key="filteredMembers.length"
            @removeMember="handleRemove"
            v-if="loaded && filteredMembers.length > 0"
          />
          <empty-message v-if="loaded && members.length == 0">
            No selectors found, invite new ones.
          </empty-message>
        </fade-transition>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import InvitationsTable from '@/components/Tables/InvitationsTable';
  import TeamTable from '@/components/Tables/TeamMembersTable';
  import TableLoader from '@/components/Loaders/TableLoader';

  export default {
    name: 'InviteSelectors',
    components: { InvitationsTable, TableLoader, TeamTable },
    data: function () {
      return {
        form: {},
        showInvitationsTable: false,
        invite: {
          name: '',
          email: '',
        },
        fields: [
          {
            key: 'name',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Name',
              layout: 'half',
            },
          },
          {
            key: 'email',
            type: 'input',
            rules: 'email',
            templateOptions: {
              type: 'email',
              label: 'Email',
              layout: 'half',
            },
          },
        ],
      };
    },
    created() {
      this.fetchInvitations();
      this.$store.dispatch('team/GET_TEAM', this.team);
    },
    methods: {
      handleRemove(payload) {
        this.$store.dispatch('team/REMOVE_MEMBER', {
          team_id: this.team,
          member: payload,
        });
      },
      handleSelectorInvite() {
        this.$store
          .dispatch('invitations/SEND_INVITATION', {
            team: this.team,
            ...this.invite,
          })
          .then(() => {
            this.invite.email = '';
            this.invite.name = '';
            this.$refs.invitationForm.reset();
          });
      },
      fetchInvitations() {
        if (this.$store.state.invitations.pending.length == 0 && this.team) {
          this.$store.dispatch('invitations/GET_INVITATIONS', {
            team: this.team,
          });
        }
      },
    },
    computed: {
      ...mapState({
        loaded: (state) => state.invitations.status,
        pending: (state) =>
          state.invitations.pending ? state.invitations.pending : [],
        team: (state) => (state.user.team ? state.user.team.id : null),
        user: (state) => (state.user.profile ? state.user.profile : null),
        members: (state) => (state.team.members ? state.team.members : null),
      }),
      filteredMembers() {
        return this.members.filter((item) => item.id !== this.user.id);
      },
    },
    watch: {
      team() {
        this.fetchInvitations();
      },
    },
  };
</script>
